<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-name"
        v-model="$v.evaluation.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <div
        v-b-tooltip.v-secondary="'Editar Descripción'"
        class="evaluation-form-description rich-text-content"
        v-html="$v.evaluation.description.$model"
        @click="
          $bvModal.show(`edit-evaluation-description-modal-${evaluation.id}`)
        "
      ></div>
      <b-modal
        :id="`edit-evaluation-description-modal-${evaluation.id}`"
        title="Editar Descripción de la Evaluación"
        size="lg"
        hide-footer
      >
        <NewRichTextEditor
          :Object="evaluation"
          :Text="evaluation.description"
          @save="patchEvaluation"
          @close="
            $bvModal.hide(`edit-evaluation-description-modal-${evaluation.id}`)
          "
        ></NewRichTextEditor>
      </b-modal>
      <!-- <b-form-input
        id="input-description"
        v-model="$v.evaluation.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
        size="sm"
      ></b-form-input> -->
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Tipo de Evaluación"
      label-for="select-type"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-type"
        v-model="$v.evaluation.type.$model"
        :options="evaluation_types"
        value-field="id"
        text-field="value"
        @change="changedEvaluationType"
        :state="validateState('type')"
        aria-describedby="select-type-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-type-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Ponderación"
      label-for="input-preferred_weighing"
      label-cols="4"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-preferred_weighing"
        v-model="$v.evaluation.preferred_weighing.$model"
        :state="validateState('preferred_weighing')"
        type="number"
        :disabled="evaluation.type == 2 || evaluation.type == 1"
        aria-describedby="input-preferred_weighing-feedback"
        size="sm"
        min="0"
      ></b-form-input>

      <b-form-invalid-feedback id="input-preferred_weighing-feedback">
        <template v-if="evaluation.type == 1 || evaluation.type == 2">
          Este campo debe ser 0
        </template>
        <template v-else-if="evaluation.type == 3">
          <span v-if="evaluation.preferred_weighing > 100">
            Este campo debe ser menor o igual 100.
          </span>
          <span v-if="evaluation.preferred_weighing <= 0">
            Este campo debe ser mayor de 0.
          </span>
        </template>
        <template v-else> Este campo es requerido.</template>
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- <b-form-group
      label="Categoría"
      label-for="select-evaluation_categories"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-evaluation_categories"
        v-model="$v.evaluation.category.$model"
        :options="evaluation_categories"
        value-field="id"
        text-field="value"
        :state="validateState('category')"
        aria-describedby="select-evaluation_categories-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-evaluation_categories-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <div>
      <b-form-group
        label="Docentes"
        label-for="select-editors"
        label-cols="0"
        label-cols-sm="3"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <b-input-group class="mb-1">
          <b-form-input
            v-model="input_search_profesor"
            type="search"
            size="sm"
            placeholder="Buscar Docentes"
          >
          </b-form-input>
          <b-input-group-append>
            <b-button
              :disabled="!input_search_profesor"
              @click="input_search_profesor = ''"
              size="sm"
              >Limpiar</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <b-form-select
          v-model="$v.evaluation.editors.$model"
          size="sm"
          multiple
        >
          <option
            v-for="item in fillProfessorsLists"
            :key="item.id"
            :value="item.id"
          >
            {{ item.first_name }} {{ item.last_name }}
          </option></b-form-select
        >
      </b-form-group>
      <b-form-group
        :label="$getVisibleNames('teaching.section', true, 'Secciones')"
        label-for="select-editors"
        label-cols="0"
        label-cols-sm="3"
        label-cols-md="3"
        class="m-0 p-0"
      >
        <b-input-group class="mb-1">
          <b-form-input
            v-model="input_search_section"
            type="search"
            size="sm"
            :placeholder="`Buscar ${$getVisibleNames(
              'teaching.section',
              true,
              'Secciones'
            )}`"
          >
          </b-form-input>
          <b-input-group-append>
            <b-button
              :disabled="!input_search_section"
              @click="input_search_section = ''"
              size="sm"
              >Limpiar</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <b-form-select
          v-model="$v.section_evaluation.$model"
          size="sm"
          multiple
          aria-describedby="input-section_evaluation-feedback"
          :state="validateStateSE()"
        >
          <option
            v-for="item in fillSectionsLists"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </b-form-select>
        <b-form-invalid-feedback id="input-section_evaluation-feedback"
          >Este campo debe contener al menos 1
          {{
            $getVisibleNames("teaching.section", false, "Sección")
          }}.</b-form-invalid-feedback
        >
      </b-form-group>
    </div>
    <div style="text-align: right">
      <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  numeric,
  maxValue,
} from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
// import { toast } from "@/utils/utils";
export default {
  name: "EvaluationTableForm",
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    Evaluation: {
      type: Object,
    },
    egressProfileMatte: {
      type: Object,
    },
    SectionEvaluations: {
      type: Array,
    },
  },
  data() {
    return {
      evaluation: {
        id: this.Evaluation ? this.Evaluation.id : generateUniqueId(),
        name: this.Evaluation ? this.Evaluation.name : "",
        description: this.Evaluation ? this.Evaluation.description : "",
        preferred_weighing: this.Evaluation
          ? this.Evaluation.preferred_weighing
          : null,
        type: this.Evaluation ? this.Evaluation.type : null,
        // category: this.Evaluation ? this.Evaluation.category : null,
        editors: [],
      },
      input_search_profesor: "",
      input_search_section: "",
      section_evaluation: [],
    };
  },
  validations: {
    evaluation: {
      name: { required },
      description: {},
      preferred_weighing: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100),
        is_valid() {
          if (this.evaluation.type != 3) {
            if (this.evaluation.preferred_weighing == 0) return true;
            else return false;
          } else {
            if (this.evaluation.preferred_weighing > 0) return true;
            else return false;
          }
        },
      },
      type: { required },
      // category: { required },
      editors: {},
    },
    section_evaluation: {
      is_Valid() {
        if (this.section_evaluation.length > 0) return true;
        return false;
      },
    },
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      evaluation_types: names.EVALUATION_TYPES,
      evaluation_categories: names.EVALUATION_CATEGORIES,
      sections: names.SECTIONS,
      user: "getUser",
      institution: "getInstitution",
    }),
    fillProfessorsLists: function () {
      return this.users
        .filter((item) => {
          return (
            (item.is_professor &&
              this.$filtered(
                String(item.first_name + " " + item.last_name),
                this.input_search_profesor
              )) ||
            this.evaluation.editors.includes(item.id)
          );
        })
        .sort(function (a, b) {
          return a.first_name
            .toLowerCase()
            .localeCompare(b.first_name.toLowerCase());
        });
    },
    fillSectionsLists: function () {
      if (!this.institution.actual_period) return [];
      return this.sections
        .filter((item) => {
          if (this.egressProfileMatte) {
            return (
              (this.user.sections_as_professor.includes(item.id) &&
                this.egressProfileMatte.id == item.egress_profile_matter &&
                this.$filtered(String(item.name), this.input_search_section) &&
                this.institution.actual_period.id == item.period) ||
              this.section_evaluation.includes(item.id)
            );
          } else {
            return this.user.sections_as_professor;
          }
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.evaluation[name];
      return $dirty ? !$error : null;
    },
    validateStateSE() {
      const { $dirty, $error } = this.$v.section_evaluation;
      return $dirty ? !$error : null;
    },
    changedEvaluationType() {
      if (this.evaluation.type == 1 || this.evaluation.type == 2) {
        this.evaluation.preferred_weighing = 0;
      }
    },
    save() {
      this.$v.evaluation.$touch();
      this.$v.section_evaluation.$touch();
      if (
        this.$v.evaluation.$anyError ||
        this.$v.section_evaluation.$anyError
      ) {
        return;
      }
      this.evaluation.editors = [...new Set(this.evaluation.editors)];
      if (isNaN(this.evaluation.id)) this.create();
      else this.update();
    },
    create() {
      this.$emit("created", this.evaluation, this.section_evaluation);
    },
    update() {
      this.$emit(
        "updated",
        this.evaluation,
        this.section_evaluation,
        this.SectionEvaluations
      );
    },
    patchEvaluation(object, text) {
      this.evaluation.description = text;
    },
  },
  mounted() {
    if (this.Evaluation) {
      this.Evaluation.editors.forEach((x) => {
        if (x.id) {
          this.evaluation.editors.push(x.id);
        } else {
          this.evaluation.editors.push(x);
        }
      });
      this.evaluation.editors = [...new Set(this.evaluation.editors)];
    }
    if (this.SectionEvaluations) {
      this.SectionEvaluations.forEach((section) => {
        this.section_evaluation.push(section.section);
      });
    }
  },
  created() {
    this.$store.dispatch(names.FETCH_USERS);
    this.$store.dispatch(names.FETCH_EVALUATION_TYPES);
    this.$store.dispatch(names.FETCH_EVALUATION_CATEGORIES);
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.evaluation-form-description {
  width: calc(100% - 5px);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
</style>